* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #fff;
}
/**/
body {
  background-color: #242625;
}

p {
  color: #333333;
}

p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 48px;
}

h1 {
  color: #000000;
}

@media (max-width: 1344px) {
  body {
    max-width: 1344px;
  }
}

@media (max-width: 1200px) {
  body {
    max-width: 1200px;
  }
}

@media (max-width: 944px) {
  body {
    max-width: 944px;
  }
}

@media (max-width: 704px) {
  body {
    max-width: 704px;
  }
}

@media (max-width: 454px) {
  body {
    width: 454px;
  }
}
