.loader-div {
  position: absolute;
  margin-top: 350px;
  margin-bottom: 350px;
  right: 47.5%;
}

.loader {
  height: 80px;
  width: 80px;
  border: transparent solid 3px;
  border-left-color: orangered;
  border-top-color: aqua;
  border-radius: 100%;
  animation: loading 5s infinite linear;
}

.loader::before {
  position: absolute;
  content: "";
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: transparent solid 3px;
  border-left-color: orangered;
  border-top-color: aqua;
  border-radius: 100%;
  animation: loading 2s infinite reverse;
}

.loader::after {
  position: absolute;
  content: "";
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: transparent solid 3px;
  border-left-color: orangered;
  border-top-color: aqua;
  border-radius: 100%;
  animation: loading 4s infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 454px) {
  .loader-div {
    right: 38%;
  }
}
